@value white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value lg from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .splashContainer {
    position: fixed;
    inset: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    opacity: 1;
    text-align: center;
  }

  .splashContentWrapper {
    margin-top: 30vh;
  }

  .splashContent {
    color: white;
    max-width: 350px;
  }

  .sticker {
    margin-bottom: lg;
    width: 185px;
  }

  .splashVisible {
    animation-name: fadeOutOpacity;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
  }

  @keyframes fadeOutOpacity {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
}
