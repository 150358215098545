@value breakpointSmDown, breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightGrey1, lightGrey2, white from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media breakpointSmUp {
      flex-direction: row;
    }
  }

  .toolbarWrapper {
    border-bottom: 1px lightGrey1 solid;
  }

  .toolbarContainer {
    width: 100%;
    padding: md;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @media breakpointSmDown {
      flex-wrap: wrap;
    }
  }

  .buttonToolbar {
    display: flex;
    flex-direction: row;
    column-gap: md;

    & button {
      padding: xs md;
    }
  }

  .selectedItemsCountContainer {
    min-width: 110px; /* An extra space so that the counter doesn't jump when changing 0 -> 1 etc. */
    display: flex;
    align-items: center;
  }

  .linkButton {
    font-size: 14px;
    margin-bottom: sm;

    /* Center the icon */
    & > div {
      margin-left: sm;
      display: flex;
      align-items: center;
    }
  }

  .linkButtonWrapper {
    text-align: right;
  }

  .break {
    display: none;

    @media breakpointSmDown {
      flex-basis: 100%;
      height: 0;
    }
  }

  .draftControls {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
  }

  .draftControlsBreakPointSmDown {
    @media breakpointSmDown {
      margin-top: 10px;
    }
  }
}

@layer overrides {
  .readyToPostFilter {
    flex-direction: row-reverse;
    gap: sm;
  }

  .readyToPostFilter--disabled {
    & > span {
      color: lightGrey2;
    }

    & input[type='checkbox']:not(:checked)::before {
      background-color: white;
      border-color: lightGrey2;
    }
  }
}
