@value xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lgMinPx from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .offersContainer {
    padding: xl;
  }

  .offersListsContainer {
    margin: 0 auto;
    max-width: lgMinPx;
  }

  .offersInactiveOffersListContainer {
    margin-top: 32px;
  }
}
