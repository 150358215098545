@value sm, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value blue from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .title {
    display: inline-block;
    margin-right: sm;
  }

  .content {
    padding: 0 xl xl;

    li {
      list-style: none;
    }
  }

  .ctaButton {
    border: none;
    padding: 0;
    color: blue;
    background: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
