@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .conditionFilterDropdownContents {
    padding: 0;
  }

  .conditionFilterDropdownOptionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: md;

    p {
      font-family: var(--font-GtAmericaExtended);
      font-weight: var(--font-weight);
    }
  }
}
