@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";


@layer components {
  .searchWrapper {
    display: flex;
    justify-content: flex-start;
    width: 50%;
  }

  .desktopWrapper {
    display: none;

    @media breakpointSmUp {
        display: block;
      }
  }

  .mobileWrapper {
    display: flex;
    flex-direction: row;
    column-gap: md;
    margin-bottom: md;

    @media breakpointSmUp {
        display: none;
      }
  }
}

@layer overrides {
  .sellingSearchContainer {
    margin-left: 0;
  }
}
