@value green, red from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .wrapper {
    position: relative;
  }

  .validRule {
    color: green;
  }

  .invalidRule {
    color: red;
  }

  .validationIcon {
    color: var(--color);
  }

  .passwordField {
    margin-bottom: xs;

    input {
      width: calc(100% - xl);
    }
  }

  .validationRule {
    display: flex;
    align-items: center;
    gap: sm;
    margin-bottom: xs;
  }

  .passwordVisibilityButton {
    position: absolute;
    right: md;
    top: md;
  }
}
