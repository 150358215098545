@value red from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .wrapper {
    display: flex;
    justify-content: center;
  }

  .rowWrapper {
    composes: wrapper;
    align-self: stretch;
    flex-direction: column;
    gap: sm;
  }

  .columnWrapper {
    composes: wrapper;
    gap: lg;
  }

  .errorText {
    color: red;
    text-align: center;
    margin-bottom: xs;
  }

  .loader {
    margin: 0 auto;
  }
}
