@value black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .imgWrapper {
    display: inline-block;
    line-height: 0;
    padding: sm;
    border-radius: 8px;
    border: 1px solid black;
    margin-bottom: sm;
    width: 76px;
  }
}
