@value black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value md, sm, xl, xs from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .createNew {
    font-size: 0.875rem;

    /* Center the icon */
    & > span {
      display: flex;
      align-items: center;

      svg {
        margin-left: sm;
      }
    }
  }

  .introText {
    margin-bottom: md;
  }

  .modalContent {
    padding: 0 xl xl xl;
  }

  .form {
    margin-top: xl;
  }

  .select {
    margin-bottom: xl;
  }

  .activeDropdown {
    margin-top: 120px;
  }

  .noTemplatesContainer {
    border: 1px solid black;
    border-radius: 10px;
    margin-bottom: xl;
    padding: sm md;
    display: flex;
    align-items: center;
  }

  .iconWrapper {
    width: 24px;
  }

  .heading {
    font-size: 14px;
    margin-bottom: xs;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    margin-left: md;
  }
}
