@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .title {
    margin-bottom: sm;
  }

  .container {
    text-align: center;
  }
}
