@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .noSize {
    padding-left: sm;
  }

  .sizeSelect {
    width: 132px;
  }

  .disabled {
    color: lightGrey2;

    &:hover {
      cursor: not-allowed;
    }
  }
}
