@value breakpointLgUp, breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value red from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .modalContainer {
    margin: md xl;
    display: flex;
    flex-direction: column;
    row-gap: lg;
    height: 80dvh;
    overflow-y: auto;
    padding: 0 1px;

    @media breakpointLgUp {
      height: 60dvh;
    }
  }

  .loadingScreenContainer {
    height: 80%;
  
    @media breakpointSmUp {
      margin: 0 140px;
    }
  }

  .flexGrow {
    flex-grow: 1;
  }

  .buttonToolbar{
    display: flex;
    flex-direction: row;
    column-gap: sm;
  }

  .ctaButton{
    flex-grow: 1;
  }

  .existingValueWarning{
    display: flex;
    flex-direction:row;
    column-gap: sm;
    align-items:center;
  }

  .errorText {
    color: red;
  }
}

@layer overrides {
  .errorContainer {
    margin-top: 0;
    padding: 10px 0;
    min-height: 10px;
  }
}
