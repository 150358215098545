@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value silverChalice, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .colourFilterDropdownContents {
    padding: 0;
    overflow-y: scroll;

    @media breakpointLgUp {
      max-height: 500px;
      overflow-y: unset;
    }
  }

  .colourFilterColourSpot {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: var(--background-color);
    background-image: var(--background-image);
  }

  .greyBorder {
    border: 1px solid silverChalice;
  }

  .colourFilterDropdownOptionContainer {
    display: flex;
    align-items: center;
    gap: sm;
  }

  .colourFilterDropdownOptionLabel {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: var(--font-weight);
    font-family: var(--font-GtAmericaExtended);
  }

  .colourFilterOptionLabelContainer {
    display: flex;
    gap: sm;
    align-items: center;
    font-size: 14px;
  }
}
