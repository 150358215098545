@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white, governorBay from "@depop/web-ui-kit/theme/default/colors.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";

@layer components {
  .draftsTableContainer {
    height: 52vh;
    display: flex;
    flex-direction: column;

    @media (min-height: 900px) {
      height: 60vh;
    }

    @media (min-height: 1050px) {
      height: 67vh;
    }

    @media (min-height: 1150px) {
      height: 69vh;
    }

    @media (min-height: 1300px) {
      height: 72vh;
    }

    @media (min-height: 1400px) {
      height: 74vh;
    }

    @media (min-height: 1600px) {
      height: 78vh;
    }

    @media (min-height: 1700px) {
      height: 79vh;
    }
  }

  .modalLoaderContainer {
    margin: md;
    height: 80dvh;

    @media breakpointLgUp {
      height: 60dvh;
    }
  }

  .warningBannerContainer {
    margin-bottom: md;
  }

  .toastLink {
    font-family: fontFamilyBody;
    color: governorBay;
    font-size: 14px;
  }

  .errorContainer {
    margin-top: 22px;
    padding: lg 0 lg;
    text-align: center;
  }

  .errorRetryButton {
    margin: 20px auto 0;
  }

  .footerText {
    padding: md;
    position: fixed;
    bottom: 0;
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 20;
  }
}
