@value lightGrey1, gallery from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  /*
  These have to be done as pixels rather than percentages due to the way
  background-position works with percentages. Any placeholders bigger than 500px
  may not look quite right.
*/
  @keyframes placeholderShimmer {
    0% {
      background-position: -500px 0;
    }

    100% {
      background-position: 500px 0;
    }
  }

  .shimmerStyles {
    background-image: linear-gradient(
      to right,
      lightGrey 0%,
      gallery 20%,
      lightGrey 40%,
      lightGrey 100%
    );
    background-repeat: no-repeat;
    background-size: 500% 100%;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }

  .rectangle {
    composes: shimmerStyles;
    background-color: lightGrey;
    width: var(--width);
    height: var(--height);
    border-radius: xs;
  }

  .circle {
    composes: shimmerStyles;
    border-radius: 50%;
    background-color: lightGrey;
    width: var(--width);
    height: var(--height);
  }
}
