@value red from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .form {
    display: flex;
    flex-direction: column;
    gap: md;
  }

  .consentCheckbox span {
    font-size: 12px;
  }

  .submitButton {
    margin-bottom: sm;
  }

  .errorText {
    color: red;
    text-align: center;
  }
}
