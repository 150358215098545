@value sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value red from "@depop/web-ui-kit/theme/default/colors.module.css";
@value AUTH_CONTAINER_MAX_WIDTH from "../styles.module.css";

@layer components {
  .loginPageContainer {
    display: flex;
    flex-direction: column;
    max-width: AUTH_CONTAINER_MAX_WIDTH;
    width: 100%;
    margin: xl auto;
    padding: xl md 80px;
  }

  .errorText {
    font-size: 12px;
    color: red;
    margin-bottom: lg;
    text-align: center;
  }

  .loginOptionsContainer {
    display: flex;
    flex-direction: column;
  }

  .ssoLoginOptionsContainer {
    display: flex;
    flex-direction: column;
    order: var(--order);
    gap: sm;
  }

  .emailLoginOptionsContainer {
    order: var(--order);
  }
}
