@value blue from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .legalTerms {
    text-align: center;

    a {
      color: blue;
    }

    a:visited {
      color: blue;
    }
  }
}
