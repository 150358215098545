@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value midGrey, lightGrey1_r, lightGrey1_g, lightGrey1_b, midGrey_r, midGrey_g, midGrey_b from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .onSaleFilterTrigger {
    width: fit-content;
    padding: 10px 0 10px lg;
    margin: sm 0;
    border-bottom: unset;

    span {
      line-height: 1;
    }

    @media breakpointLgUp {
      padding: 7px 10px;
      margin: 0;
      border-bottom: 1px solid;
    }
  }
}
