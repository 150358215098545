@value md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .header {
    margin: 48px 0 lg;
    text-align: center;

    @media breakpointSmUp {
      margin: 48px 0;
    }
  }

  .title {
    font-size: 2rem;
    margin-bottom: md;
  }

  .description {
    max-width: 820px;
    margin: 0 auto;
  }
}

@layer overrides {
  .container {
    margin: 0 auto 120px;
  }
}
