@value sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value blue, red from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .phoneConfirmForm {
    width: 100%;
  }

  .phoneConfirmInput {
    margin-top: xl;
    width: 100%;
  }

  .phoneConfirmError {
    color: red;
    margin: 0 0 sm;
  }

  .phoneConfirmResendButton {
    margin: xl 0 lg 0;
    font-size: 12px;
    width: 100%;
    justify-content: center;
    color: blue;

    span {
      font-weight: normal;
      font-family: var(--font-GtAmericaExtended);
    }
  }

  .phoneConfirmSubmitButton {
    width: 100%;
    margin-top: md;
  }

  .badCodeErrorButton {
    font-size: 12px;
    color: blue;
    padding: 0;
    display: inline-flex;

    span {
      font-weight: normal;
      font-family: var(--font-GtAmericaExtended);
    }
  }
}
