@value md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .title {
    font-size: 2rem;
  }
}

@layer overrides {
  .article {
    margin: lg auto 220px;

    @media breakpointSmUp {
      margin: 48px auto 160px;
    }
  }
}

