@value breakpointMdUp, breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    max-width: unset;
  }

  .messageContent {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    padding: lg;

    @media breakpointSmUp {
      max-width: 340px;
    }

    @media breakpointSmDown {
      max-width: 340px;
    }
  }

  .title {
    margin: 0 0 sm;
  }

  .ctaWrapper {
    display: flex;
    margin: md auto 0;
  }

  .graphicWrapper {
    display: flex;
    justify-content: center;
    margin-top: 64px;
  }

  .explodingHeadSvg {
    width: 148px;
    height: auto;
  }

  .emptySearch {
    margin-top: lg;
  }
}
