@value lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .divider {
    width: 100%;
    display: flex;
    align-items: center;
    margin: md 0 xl;
    text-align: center;
    gap: xs;
    order: var(--order);

    &::before {
      content: '';
      flex-grow: 1;
      border-top: 1px solid lightGrey2;
    }

    &::after {
      content: '';
      flex-grow: 1;
      border-top: 1px solid lightGrey2;
    }
  }
}
