@value md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
    .modalWrapper {
        padding: 0 xl xl;
    }

    .modalText {
        margin-bottom: md;
    }

    .subtitle {
        margin-top: md;
    }

    .retryWrapper {
        text-align: center;
        padding-top: md;
    }
}