@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
    .wrapper {
        display: flex;
        word-break: break-all;
        overflow-wrap: break-word;
    }

    .imageWrapper {
        height: 88px;
        margin-right: md;
    }

    .soldOn {
        margin-bottom: md;
    }

    .postcode {
        display: block;
        text-transform: uppercase;
    }
}