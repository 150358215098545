@value xs, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    padding: 0 xl xl;
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: xl;
  }

  .errorMessageContainer {
    text-align: center;
    margin-top: xs;
  }

  .tabs {
    margin: xl 0 0;
    width: 100%;
  }
}
