@value md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    display: flex;
    gap: md;

    @media breakpointSmUp {
      gap: xl;
    }
  }

  .followCount {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 0;
    background-color: transparent;
    text-align: left;
    cursor: pointer;

    @media breakpointSmUp {
      display: inline-block;
      text-align: center;
    }
  }

  .followCountOwnShop {
    display: inline-block;
  }

  .followRole {
    font-size: 0.75rem;

    @media breakpointSmUp {
      font-size: 0.875rem;
    }
  }
}
