@value black from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
    .icon {
        color: black;
    }

    .iconContainer {
        display: flex;
    }
}

@layer overrides {
    .hidden {
        display: none;
    }
}