@value black, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .videoIcon {
    position: absolute;
    bottom: sm;
    left: sm;
    color: white;
  }

  .productAttributesContainer {
    margin: sm xs 0;
  }

  .hoverImage {
    filter: brightness(0.7);
  }

  .translucentHoverOverlay {
    height: 100%;
    background: black;
    opacity: 0.3;
  }
}
