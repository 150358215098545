@value lightGrey2, lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .wrapper {
    width: 375px;
  }

  .content {
    min-height: 300px;
    max-height: 450px;
    overflow-y: auto;
    padding-left: md;
    padding-right: md;
  }

  .ctaWrapper {
    background-color: lightGrey1;
    border-top: 1px solid lightGrey2;
    padding: md;
  }

  .listItem {
    list-style-type: none;
  }

  .line {
    width: 100%;
    border: 2px solid lightGrey1;
    margin-bottom: md;
  }
}
