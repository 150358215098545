@value red, black, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    margin: 0 auto;
    padding: 0 md;
    width: 100%;
    max-width: 1280px;
  }
  
  .content {
    display: flex;
    align-items: center;
    height: calc(100vh - 100px);
  }

  .message {
    margin-bottom: md;
      
    &a,
      a:visited {
        color: black;
      }
  }

  .description {
    display: block;
    color: midGrey;

    &a,
      a:visited {
        color: red;
      }
  }
}
