@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .productList {
    display: grid;
    gap: sm;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media breakpointSmUp {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media breakpointMdUp {
      gap: md;
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }

  .error {
    margin: 0;
  }
}

