@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .wrapper {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }

  .avatarWrapper {
    font-size: 0;
    margin-right: sm;
  }

  .userInfo {
    min-width: 0;
  }

  .name {
    word-break: break-all;
  }

  .username {
    word-break: break-all;
  }
}
