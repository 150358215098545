@value black, blue, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .wrapper {
    display: flex;
    border: 2px solid black;
    padding: md;
    text-align: left;
    color: black;
    margin-bottom: lg;

    @media breakpointMdUp {
      margin-bottom: xl;
    }

    div:first-of-type {
      margin-right: md;
    }
  }

  .mainText {
    margin-bottom: lg;
  }

  .ctaWrapper {
    margin-bottom: lg;
  }

  .stripeTerms {
    margin-bottom: 40px;
    color: midGrey;
    font-size: 10px;

    a {
      text-decoration: none;
      color: blue;
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
