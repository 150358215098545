@value xs, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp, breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .skeleton {
    max-width: 75%;

    @media breakpointSmUp {
      max-width: 30%;
      min-width: 400px;
    }
  }

  .productBreadcrumbs {
    margin-bottom: xs;

    @media breakpointMdUp {
      margin-bottom: sm;
    }
  }
}
