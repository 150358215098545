@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black, white from "@depop/web-ui-kit/theme/default/colors.module.css";

/*
  If you wish make any changes, see here for styling guidelines: https://developer.apple.com/design/human-interface-guidelines/technologies/sign-in-with-apple#sign-in-with-apple-buttons
  
  This button is paired with the "Continue with Google" button.
  We have limited control of the Google button's styling.
  Therefore some styles here have been set to match Google's
*/
@layer components {
  .wrapper {
    height: 40px;
    background-color: #000;
    border-radius: var(--borderRadius);
    margin-bottom: sm;
    transition: all 0.2s ease;
    min-width: var(--minWidth);

    &:hover {
      background-color: rgba(0, 0, 0, 0.85);
    }
  }

  .appleButton {
    padding: 0;
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont;
    color: white;

    /* 17px conform with Apple's font policy */
    font-size: 17px;
    line-height: 1;
    cursor: pointer;
    display: flex;
    justify-content: var(--justifyContent);
    align-items: center;
  }

  .appleIconWrapper {
    padding-left: var(--paddingLeft);
  }

  .buttonText {
    white-space: nowrap;
    flex: 1;
  }
}
