@value blue from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .ctaButton {
    border: none;
    padding: 0;
    color: blue;
    background: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .productListItem {
    list-style: none;
  }
}
