@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .paymentWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: md;
  }

  .total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: sm 0;
  }

  .shipping {
    margin-bottom: lg;
  }
}

@layer overrides {
  .totalPrice {
    p {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
